<template>
  <div>
    <!-- Campaigns List -->
    <vs-row
      v-if="!showDetails"
      vs-justify="center"
    >
      <vs-col
        vs-lg="12"
        vs-xs="12"
        vs-sm="12"
      >
        <vs-card>
          <vs-table
            id="campaign-table"
            ref="campaign"
            pagination
            search
            stripe
            class="text-nowrap"
            :max-items="15"
            :data="campaigns"
            :no-data-text="noDataText"
          >
            <div slot="header">
              <h3>Lista de Campanhas</h3>
            </div>

            <template slot="thead">
              <vs-th>Configurar</vs-th>
              <vs-th>Nome</vs-th>
              <vs-th>Data</vs-th>
              <vs-th>Horário</vs-th>
              <vs-th>Resultado</vs-th>
              <vs-th>Etapas</vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr
                v-for="(campaign, campIdx) in data"
                :key="campIdx"
              >
                <!-- Actions -->
                <vs-td>
                  <vs-button
                    v-if="containsError(campaign.etapas[0].execResult)"
                    class="text-dark p-0"
                    color="transparent"
                    radius
                    @click="goToCampaign(campaign)"
                  >
                    <vs-tooltip
                      :text="campaign.etapas[0].execResult"
                      position="top"
                    >
                      <i
                        class="text-danger fa fa-times m-2"
                        aria-hidden="true"
                      />
                    </vs-tooltip>
                  </vs-button>
                  <vs-button
                    v-else
                    color="transparent"
                    class="text-dark p-0"
                    radius
                    @click="goToCampaign(campaign)"
                  >
                    <i
                      v-if="campaign.campanha.status === 6"
                      class="text-secondary mdi mdi-account-convert ml-2"
                      aria-hidden="true"
                    />
                    <i
                      v-if="campaign.campanha.status === 2"
                      class="text-success fa fa-check-circle ml-2"
                      aria-hidden="true"
                    />
                    <i
                      v-else
                      class="text-secondary fa fa-wrench m-2"
                      aria-hidden="true"
                    />
                  </vs-button>
                </vs-td>

                <!-- Name -->
                <vs-td :data="campaign.campanha.nome">
                  <div class="d-flex align-items-center">
                    <span class="text-black">
                      {{ campaign.campanha.nome }}
                    </span>
                  </div>
                </vs-td>

                <!-- Date -->
                <vs-td
                  v-if="campaign.campanha.dataFim"
                  :data="campaign.campanha.dataFim"
                >
                  <div class="d-flex align-items-center">
                    <span class="text-black">
                      {{ getDate(campaign.campanha.dataFim) }}
                    </span>
                  </div>
                </vs-td>
                <vs-td v-else>
                  <div class="d-flex align-items-center">
                    <span class="text-muted">
                      Indisponível
                    </span>
                  </div>
                </vs-td>

                <!-- Time -->
                <vs-td
                  v-if="campaign.campanha.dataFim"
                  :data="campaign.campanha.dataFim"
                >
                  <div class="d-flex align-items-center">
                    <span class="text-black">
                      {{ getTime(campaign.campanha.dataFim) }}
                    </span>
                  </div>
                </vs-td>
                <vs-td v-else>
                  <div class="d-flex align-items-center">
                    <span class="text-muted">
                      Indisponível
                    </span>
                  </div>
                </vs-td>

                <!-- Show Details -->
                <vs-td>
                  <vs-button
                    class="ml-auto"
                    :disabled="containsError(campaign.etapas[0].execResult)"
                    size="large"
                    title="Visualizar campanha"
                    @click="getCampaign(campaign.campanha.id)"
                  >
                    <i class="fas fa-chart-pie mr-2" />

                    Visualizar
                  </vs-button>
                </vs-td>

                <!-- Campaign Status -->
                <vs-td>
                  <i :class="getStatusStyle(campaign.campanha.status)" />
                  <i class="icon-muted fab fa-whatsapp m-2" />
                  <i class="icon-muted fas fa-sms m-2" />
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vs-card>
      </vs-col>
    </vs-row>

    <!-- Campaign Details -->
    <div v-if="showDetails">
      <!-- Show List -->
      <vs-button
        color="primary"
        class="mb-4"
        @click="goToList"
      >
        <i class="fa fa-undo-alt" />

        Voltar para listas
      </vs-button>

      <!-- Campaign Stats -->
      <!-- Type 1 -->
      <vs-row
        v-if="selectedCampResult.etapas[0].tipo === 1"
        vs-justify="center"
      >
        <vs-col
          vs-lg="6"
          vs-xs="12"
          vs-sm="12"
        >
          <vs-card class="card-h500">
            <!-- Header -->
            <header slot="header">
              <div v-if="campaignMsg">
                <h3 class="mb-0">
                  <b>{{ campaignMsg.subject }}</b>
                </h3>

                <h6 class="font-weight-light text-muted mt-4">
                  Enviado por
                </h6>

                <h4 class="mb-0 mt-1">
                  {{ campaignMsg.sender_name }}
                </h4>

                <h4 class="mb-0 mt-1">
                  ({{ campaignMsg.sender_email }})
                </h4>
              </div>
              <h2
                v-else
                class="mb-0"
              >
                Dados indisponíveis
              </h2>
            </header>

            <!-- Sent -->
            <div>
              <h3
                v-if="sent !== undefined"
                class="mb-1"
              >
                <b>{{ sent | formatLocale }}</b>
              </h3>
              <h3
                v-else
                class="mb-1 mt-4"
              >
                Indisponível
              </h3>

              <h6 class="font-weight-light text-muted">
                Enviados
              </h6>
            </div>

            <!-- Delivered -->
            <div>
              <h3
                v-if="delivered !== undefined"
                class="mt-4 mb-1"
              >
                <b>{{ delivered | formatLocale }}</b>
              </h3>
              <h3
                v-else
                class="mb-1 mt-4"
              >
                Indisponível
              </h3>

              <h6 class="font-weight-light text-muted">
                Entregues
              </h6>
            </div>

            <!-- Errors -->
            <div>
              <h3
                v-if="errors !== undefined"
                class="mt-4 mb-0"
              >
                <b>{{ errors | formatLocale }}</b>
              </h3>
              <h3
                v-else
                class="mb-1 mt-4"
              >
                Indisponível
              </h3>

              <h6 class="font-weight-light text-muted">
                Erros
              </h6>
            </div>

            <!-- Not Read -->
            <div>
              <h3
                v-if="notRead !== undefined"
                class="mt-4 mb-0"
              >
                <b>{{ notRead | formatLocale }}</b>
              </h3>
              <h3
                v-else
                class="mb-1 mt-4"
              >
                Indisponível
              </h3>

              <h6 class="font-weight-light text-muted">
                Não lidos
              </h6>
            </div>
          </vs-card>
        </vs-col>

        <!-- Stats Chart -->
        <vs-col
          vs-lg="6"
          vs-xs="12"
          vs-sm="12"
        >
          <vs-card
            v-if="campInfos"
            class="card-h500"
            style="overflow-y: scroll; overflow-x: hidden"
          >
            <BarChart
              :key="campInfos"
              :data="campInfos.stats"
            />
          </vs-card>
        </vs-col>

        <!-- Template HTML -->
        <vs-col
          vs-justify="center"
          vs-lg="12"
          vs-xs="12"
          vs-sm="12"
        >
          <vs-card>
            <div v-if="campaignMsg">
              <!-- eslint-disable-next-line vue/no-v-html -->
              <div v-html="campaignMsg.body" />
            </div>
            <div
              v-else
              class="text-muted"
            >
              Template indisponível
            </div>
          </vs-card>
        </vs-col>
      </vs-row>

      <!-- Type 2 -->
      <vs-row
        v-if="selectedCampResult.etapas[0].tipo === 2"
        vs-justify="center"
        class="align-items-stretch d-flex"
      >
        <vs-col
          vs-lg="6"
          vs-xs="6"
          vs-sm="6"
          class="mb-3"
        >
          <vs-card class="card-full">
            <h3 class="mb-0 mt-4">
              {{ selectedCampResult.campanha.nome }}
            </h3>

            <h3 class="mb-0 mt-4">
              {{ sent | formatLocale }}
            </h3>

            <h6 class="font-weight-light text-muted">
              Enviados
            </h6>

            <h3 class="mt-4 mb-0">
              {{ errors | formatLocale }}
            </h3>

            <h6 class="font-weight-light text-muted">
              Erros
            </h6>
          </vs-card>
        </vs-col>

        <!-- Campaign Message -->
        <vs-col
          vs-lg="6"
          vs-xs="6"
          vs-sm="6"
          class="mb-3"
        >
          <vs-card class="card-full py-2">
            <h3 class="mb-4 mt-2">
              Mensagem:
            </h3>

            <div class="bubble bubble-bottom-left">
              {{ selectedCampResult.etapas[0].config.msg }}
            </div>
          </vs-card>
        </vs-col>
      </vs-row>
    </div>
  </div>
</template>

<script>
/* Components */
import BarChart from '../../dashboard-components/box-fanbase/BarChart.vue';

/* Services */
import Campaign from '@/services/campaign';

const campService = new Campaign();

export default {
  name: 'Campaigns',
  components: { BarChart },
  data: () => ({
    campaigns: [],
    noDataText: 'Nenhuma campanha encontrada',
    /* Campaign Details */
    campInfos: {},
    selectedCampResult: {},
    campaignMsg: undefined,
    delivered: undefined,
    errors: undefined,
    notRead: undefined,
    sent: undefined,
    showDetails: false,
  }),
  computed: {
    ownerId() {
      return this.$store.state.ownerID;
    },
  },
  created() {
    this.$store.commit('SET_campInfo', null);
    this.getCampaigns();
  },
  methods: {
    /* API */
    getCampaigns() {
      this.$store.dispatch('set_isLoadingActive', true);

      campService.getCampaign(this.ownerId, 0)
        .then((resp) => (this.campaigns = resp))
        .catch((err) => (this.noDataText = err))
        .finally(() => this.$store.dispatch('set_isLoadingActive', false));
    },
    getCampaign(campId) {
      this.$store.dispatch('set_isLoadingActive', true);

      campService
        .getCampaign(this.ownerId, campId)
        .then((resp) => {
          this.selectedCampResult = resp[0];
          this.campInfos = resp[0].etapas[0].infos;

          this.resetCampInfos();

          if (this.campInfos) {
            if (this.campInfos.message) {
              this.campaignMsg = this.campInfos.message;
            }

            if (this.campInfos.enviados) {
              this.sent = this.campInfos.enviados.value;
            }

            if (this.campInfos.entregues) {
              this.delivered = this.campInfos.entregues.value;
            }

            if (this.campInfos.erros) {
              this.errors = this.campInfos.erros.value;
            }

            if (this.campInfos.nlidos) {
              this.notRead = this.campInfos.nlidos.value;
            }
          }

          this.showDetails = true;
        })
        .catch((err) => alert(err))
        .finally(() => this.$store.dispatch('set_isLoadingActive', false));
    },
    /* Filters */
    getDate(timedate) {
      const parts = timedate.split('T')[0].split('-');
      return parts[2] + '/' + parts[1] + '/' + parts[0];
    },
    getTime(timedate) {
      timedate = timedate.split('T')[1];
      return timedate.substring(0, timedate.lastIndexOf(':'));
    },
    /* Helpers */
    containsError(str) {
      if (str) return str.includes('Erro');
    },
    getStatusStyle(status) {
      const iconColor = status === 2 ? 'text-success' : 'text-secondary';
      return 'fas fa-envelope m-2 ' + iconColor;
    },
    goToList() {
      this.showDetails = false;
    },
    resetCampInfos() {
      this.campaignMsg = undefined;
      this.sent = undefined;
      this.delivered = undefined;
      this.errors = undefined;
      this.notRead = undefined;
    },
    /* Router */
    goToCampaign(campaign) {
      this.$store.commit('SET_campInfo', campaign);
      this.$router.push('/campanhas/edit');
    },
  },
};
</script>

<style>
#campaign-table .vs-table--content {
  overflow: hidden !important;
}
</style>

<style scoped lang="scss">
.icon-muted {
  color: lightgray;
}

.card-h500 {
  height: 500px;
}

.card-full {
  height: 100%;
}

.bubble {
  position: relative;
  display: inline-block;
  font-family: sans-serif;
  font-size: 18px;
  line-height: 24px;
  background: #d9d9d9;
  border-radius: 40px;
  padding: 24px;
  text-align: left;
  color: #000;
}

.bubble-bottom-left:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 24px solid #d9d9d9;
  border-right: 12px solid transparent;
  border-top: 12px solid #d9d9d9;
  border-bottom: 20px solid transparent;
  left: 32px;
  bottom: -24px;
}
</style>
