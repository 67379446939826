import axios from 'axios';

const baseEnv = process.env.VUE_APP_BASEENV;
const mktDomain = process.env.VUE_APP_MKTDOMAIN;

const mktUrl = `${mktDomain}/${baseEnv}`;

export default class Campaign {
  createCampaign(payload) {
    return new Promise((resolve, reject) => {
      const url = `${mktUrl}/campaign`;

      axios
        .post(url, payload)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  executeCampaign(id, mode) {
    return new Promise((resolve, reject) => {
      const url = `${mktUrl}/ExecuteCampaign/${id}/${mode}`;

      axios
        .post(url)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getCampaign(ownerId, campId) {
    return new Promise((resolve, reject) => {
      const url = `${mktUrl}/campaign/${ownerId}/${campId}`;

      axios
        .get(url)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getCampaignConfig(ownerId) {
    return new Promise((resolve, reject) => {
      const url = `${mktUrl}/campaignopcoes/${ownerId}`;

      axios
        .get(url)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject('Erro ao retornar opções: \n' + err);
          }
        );
    });
  }

  getMessageTemplates(ownerId) {
    return new Promise((resolve, reject) => {
      const url = `${mktUrl}/templates/messagetemplates/${ownerId}`;

      axios
        .get(url)
        .then(
          (resp) => resolve(resp.data),
          (err) => reject(err),
        );
    });
  }

  getTestRecipients(ownerId) {
    return new Promise((resolve, reject) => {
      const url = `${mktUrl}/campaigntestlist/${ownerId}`;

      axios
        .get(url)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject('Erro ao retornar destinatários: \n' + err);
          }
        );
    });
  }

  updateTestRecipients(ownerId, payload) {
    return new Promise((resolve, reject) => {
      const url = `${mktUrl}/campaigntestlist/${ownerId}`;

      axios
        .post(url, payload)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject('Erro ao atualizar destinatários: \n' + err);
          }
        );
    });
  }

  updateMailingList(listId) {
    return new Promise((resolve, reject) => {
      const url = `${mktUrl}/CampaignList/${listId}`;

      axios
        .post(url)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve();
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject('Erro ao atualizar destinatários: \n' + err);
          }
        );
    });
  }

  sendSingleEmail(payload) {
    return new Promise((resolve, reject) => {
      const url = `${mktUrl}/sendMail/sendSingle`;

      axios
        .post(url, payload)
        .then((resp) => resolve(resp.data.data))
        .catch((err) => reject(err));
    });
  }

  sendSingleSMS(payload) {
    return new Promise((resolve, reject) => {
      const url = `${mktUrl}/sms/sendSingle`;

      axios
        .post(url, payload)
        .then((resp) => resolve(resp.data.data))
        .catch((err) => reject(err));
    });
  }

  sendSingleWhatsApp(payload) {
    return new Promise((resolve, reject) => {
      const url = `${mktUrl}/whatsapp/sendSingle`;

      axios
        .post(url, payload)
        .then((resp) => resolve(resp.data.data))
        .catch((err) => reject(err));
    });
  }
}
